define("front-end/models/job-offer-process", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    currentUser: Ember.inject.service(),
    type: 'job-offer-process',
    humanizedType: 'Processo de Oferta de Trabalho',
    note: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    deadline: (0, _model.attr)('string'),
    concludedAt: (0, _model.attr)('string'),
    conclusionTime: (0, _model.attr)('string'),
    price: (0, _model.attr)('number'),
    plannedDeliveryDate: (0, _model.attr)('string'),
    value: (0, _model.attr)('number'),
    manufacturerDeliveryDate: (0, _model.attr)('deadline'),
    paymentMethod: (0, _model.attr)('string'),
    material: (0, _model.attr)('string'),
    rawMaterialCost: (0, _model.attr)('number'),
    // raw material delivery info
    rawMaterialDeliveryDaysAmount: (0, _model.attr)('number'),
    rawMaterialDeliveryDate: (0, _model.attr)('string'),
    trackRawMaterialDelivery: (0, _model.attr)('boolean'),
    fromManufacturerFreightResponsible: (0, _model.attr)('string'),
    deliveryCity: (0, _model.attr)('string'),
    finalStatus: (0, _model.attr)('boolean'),
    requireMessage: (0, _model.attr)('boolean'),
    hasItems: (0, _model.attr)('boolean'),
    allowCancel: (0, _model.attr)('boolean'),
    allowNewOffers: (0, _model.attr)('boolean'),
    moderatedFieldsCompleted: (0, _model.attr)('boolean'),
    available: (0, _model.attr)('boolean'),
    completed: (0, _model.attr)('boolean'),
    failed: (0, _model.attr)('boolean'),
    canceled: (0, _model.attr)('boolean'),
    readyToSelectWinner: (0, _model.attr)('boolean'),
    hasBeenAvailable: (0, _model.attr)('boolean'),
    offerType: (0, _model.attr)('string'),
    offers: (0, _model.hasMany)('job-offer', {
      inverse: 'jobOfferProcess',
      polymorphic: true
    }),
    items: (0, _model.hasMany)('job-offer-process-item', {
      inverse: 'process'
    }),
    batches: (0, _model.hasMany)('batch', {
      inverse: null
    }),
    companies: (0, _model.hasMany)('company', {
      inverse: null
    }),
    statusMessage: Ember.computed('completed', 'canceled', 'failed', 'available', 'readyToSelectWinner', 'allowNewOffers', 'items.[]', function () {
      if (this.items.length == 0) {
        return "Processo em criação";
      }

      if (this.completed) {
        return "Processo finalizado";
      } else if (this.canceled) {
        return "Processo cancelado";
      } else if (this.failed) {
        return "Processo falhou";
      } else if (this.available) {
        return "Processo aguardando resposta dos parceiros";
      } else if (this.allowNewOffers) {
        return "Processo pronto para ser oferecido";
      } else if (this.readyToSelectWinner) {
        return "Pronto para selecionar parceiro";
      } else {
        return "Processo em criação";
      }
    }),
    level: Ember.computed('completed', 'canceled', 'failed', 'available', 'readyToSelectWinner', 'allowNewOffers', 'items.[]', function () {
      if (this.items.length == 0) {
        return 'warning';
      }

      if (this.completed) {
        return 'success';
      } else if (this.canceled) {
        return 'error';
      } else if (this.failed) {
        return 'error';
      } else if (this.available) {
        return 'info';
      } else if (this.allowNewOffers) {
        return 'warning';
      } else if (this.readyToSelectWinner) {
        return 'info';
      } else {
        return 'warning';
      }
    }),
    route: function route() {
      var _this = this;

      var companyId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.currentUser.load().then(function (user) {
        if (user.get('isAdmin')) {
          return Ember.RSVP.resolve(['admin.job-offer-processes.build', _this.id]);
        } else {
          return ['dashboard.jobs.index', companyId].filter(function (x) {
            return x;
          });
        }
      });
    }
  });

  _exports.default = _default;
});